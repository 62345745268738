<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.shop_type" class="filter-item" size="small" @change="getSchoolWithShop">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>

    <div class="search-container">
      <div class="search-tree">
        <div class="search-label">选择查询部门</div>
        <div class="search-value">
          <el-tree :data="schoolWithShops" show-checkbox default-expand-all node-key="treeId" @check-change="treeChange"></el-tree>
        </div>
      </div>
      <div class="search-options">
        <div class="search-item">
          <div class="search-label">查询日期类型</div>
          <div class="search-value">
            <el-radio v-model="listQuery.date_type" :label="1" border size="mini">按天</el-radio>
            <el-radio v-model="listQuery.date_type" :label="2" border size="mini">按月</el-radio>
            <el-radio v-model="listQuery.date_type" :label="3" border size="mini">按年</el-radio>
            <el-radio v-model="listQuery.date_type" :label="4" border size="mini">按自定义时间</el-radio>
          </div>
          <div style="margin-top:4px">
            <el-date-picker v-if="listQuery.date_type == 1" v-model="listQuery.start_date" value-format="yyyy-MM-dd" type="date" size="small" placeholder="选择日期"></el-date-picker>
            <el-date-picker v-if="listQuery.date_type == 2" v-model="listQuery.start_date" value-format="yyyy-MM-01" type="month" size="small" placeholder="选择日期"></el-date-picker>
            <el-date-picker v-if="listQuery.date_type == 3" v-model="listQuery.start_date" value-format="yyyy-01-01" type="year" size="small" placeholder="选择日期"></el-date-picker>
            <el-date-picker v-if="listQuery.date_type == 4" v-model="listQuery.start_date" value-format="yyyy-MM-dd" type="date" size="small" placeholder="选择日期"></el-date-picker>
            <el-date-picker v-if="listQuery.date_type == 4" v-model="listQuery.end_date" value-format="yyyy-MM-dd" type="date" size="small" placeholder="选择日期"></el-date-picker>
          </div>
        </div>
<!--        <div class="search-item">-->
<!--          <div class="search-label">报表类型</div>-->
<!--          <div class="search-value">-->
<!--            <el-radio v-model="listQuery.report_type" :label="1" border size="mini">结算汇总表</el-radio>-->
<!--            <el-radio v-model="listQuery.report_type" :label="2" border size="mini">结算各档口分发表</el-radio>-->
<!--          </div>-->
<!--        </div>-->
        <div class="search-item">
          <div class="search-label">报表排序</div>
          <div class="search-value">
            <el-radio v-model="listQuery.report_order" :label="1" border size="mini">档口号排序</el-radio>
            <el-radio v-model="listQuery.report_order" :label="2" border size="mini">档口名称排序</el-radio>
          </div>
        </div>
        <div class="search-item">
          <div class="search-label">餐别类型</div>
          <div class="search-value">
            <el-radio v-model="listQuery.time_type" :label="0" border size="mini">全部</el-radio>
            <el-radio v-model="listQuery.time_type" :label="1" border size="mini">早餐(6:00-10:00)</el-radio>
            <el-radio v-model="listQuery.time_type" :label="2" border size="mini">中餐(10:00-16:00)</el-radio>
            <el-radio v-model="listQuery.time_type" :label="3" border size="mini">晚餐(16:00-20:00)</el-radio>
            <el-radio v-model="listQuery.time_type" :label="4" border size="mini">夜宵(20:00-06:00)</el-radio>
            <el-radio v-model="listQuery.time_type" :label="5" border size="mini">夜宵(19:00-00:00)</el-radio>
          </div>
        </div>
<!--        <div class="search-item">-->
<!--          <div class="search-label">报表信息</div>-->
<!--          <div class="search-value">-->
<!--            <el-input v-model="report.operator_name" size="small" style="width:200px;">-->
<!--              <template slot="prepend">操作人</template>-->
<!--            </el-input>-->
<!--            <el-input v-model="report.cashier_name" size="small" style="width:200px;">-->
<!--              <template slot="prepend">出纳</template>-->
<!--            </el-input>-->
<!--            <div style="height:4px"></div>-->
<!--            <el-input v-model="report.principal_name" size="small" style="width:200px;">-->
<!--              <template slot="prepend">负责人</template>-->
<!--            </el-input>-->
<!--            <el-input v-model="report.accountant_name" size="small" style="width:200px;">-->
<!--              <template slot="prepend">会计</template>-->
<!--            </el-input>-->
<!--          </div>-->
<!--        </div>-->
        <div style="display: flex;flex-wrap: wrap;">
          <el-button :loading="btnLoading" type="primary" size="small" @click="getReport">生成报表</el-button>

          <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="财务报表.xls" worksheet="财务报表" style="margin-left: 10px;">
            <el-button type="success" size="small" :loading="exportLoading">导出</el-button>
          </download-excel>
        </div>

        <el-checkbox-group v-model="listQuery.show_columns" style="margin-top: 20px;">
          <el-checkbox v-for="(column, index) in all_columns" :key="index" :label="column.prop">{{ column.label }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

<!--    <div v-if="list" class="report-container">-->
<!--      <div class="options">-->
<!--        <el-button type="primary" size="small" icon="el-icon-printer" class="btn-print" @click="printReport">打印报表</el-button>-->
<!--        <el-button type="primary" size="small" icon="el-icon-download" class="btn-print" @click="handleExportExcel">导出Excel</el-button>-->
<!--      </div>-->
<!--      <div id="report" v-loading="listLoading" class="report">-->
<!--        <div v-if="report.report_type == 1" class="report-content">-->
<!--          <div class="water-mark">-->
<!--            <span class="mark-name" v-for="index of 100" :key="index">{{report.operator_name}}</span>-->
<!--          </div>-->
<!--          <div class="report-header">-->
<!--            <div>营业结算报表(全部)</div>-->
<!--            <div class="report-header-date">{{report.report_date}}</div>-->
<!--          </div>-->
<!--          <div class="report-label">-->
<!--            <div>单位名称: {{username}}</div>-->
<!--            <div class="report-label-right">货币单位: 元 共{{list.length}}条</div>-->
<!--          </div>-->
<!--          <table class="report-table">-->
<!--            <tr>-->
<!--              <th>店铺ID</th>-->
<!--              <th>店铺名称</th>-->
<!--              <th>交易笔数</th>-->
<!--              <th>交易金额</th>-->
<!--&lt;!&ndash;              <th>配送次数</th>&ndash;&gt;-->
<!--&lt;!&ndash;              <th>配送金额</th>&ndash;&gt;-->
<!--              <th>退款笔数</th>-->
<!--              <th>退款金额</th>-->
<!--              <th>餐盒费</th>-->
<!--              <th>配送费</th>-->
<!--              <th>商家承担配送费</th>-->
<!--              <th>用户承担配送费</th>-->
<!--              <th>其他金额</th>-->
<!--              <th>结算金额(交易金额-其他金额)</th>-->
<!--            </tr>-->
<!--            <tr v-for="(item, index) in list" :key="index">-->
<!--              <td>{{ item.id }}</td>-->
<!--              <td>{{ item.shop_name }}</td>-->
<!--              <td>{{ item.pay_count }}</td>-->
<!--              <td>{{ item.pay_money }}</td>-->
<!--&lt;!&ndash;              <td>{{ item.send_count }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;              <td>{{ item.extra_money }}</td>&ndash;&gt;-->
<!--              <td>{{ item.refund_count }}</td>-->
<!--              <td>{{ item.refund_money }}</td>-->
<!--              <td>{{ item.pack_price }}</td>-->
<!--              <td>{{ item.send_price }}</td>-->
<!--              <td>{{ item.shop_bear_send_fee }}</td>-->
<!--              <td>{{ item.user_bear_send_fee }}</td>-->
<!--              <td>{{ item.ledger_money }}</td>-->
<!--              <td>{{ item.settle_money }}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--          <div class="report-foot">-->
<!--            <div class="foot-item">-->
<!--              <div class="foot-label">使用单位: {{username}}</div>-->
<!--              <div class="foot-value">打印时间: {{report.print_date}}</div>-->
<!--            </div>-->
<!--            <div class="foot-item">-->
<!--              <div class="foot-label">操作人: {{report.operator_name}}</div>-->
<!--              <div class="foot-value">出纳: {{report.cashier_name}}</div>-->
<!--            </div>-->
<!--            <div class="foot-item">-->
<!--              <div class="foot-label">负责人: {{report.principal_name}}</div>-->
<!--              <div class="foot-value">会计: {{report.accountant_name}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else v-for="(item,index) in list" :key="index" class="report-content">-->
<!--          <div class="water-mark">-->
<!--            <span class="mark-name" v-for="index of 100" :key="index">{{report.operator_name}}</span>-->
<!--          </div>-->
<!--          <div class="report-header">-->
<!--            <div>结算分发表</div>-->
<!--            <div class="report-header-date">{{report.report_date}}</div>-->
<!--          </div>-->
<!--          <div class="report-label">-->
<!--            <div>单位名称: {{username}}</div>-->
<!--            <div class="report-label-right">货币单位: 元</div>-->
<!--          </div>-->
<!--          <table class="report-table">-->
<!--            <tr>-->
<!--              <th>店铺ID</th>-->
<!--              <th>店铺名称</th>-->
<!--              <th>交易笔数</th>-->
<!--              <th>交易金额</th>-->
<!--&lt;!&ndash;              <th>配送次数</th>&ndash;&gt;-->
<!--&lt;!&ndash;              <th>配送金额</th>&ndash;&gt;-->
<!--              <th>退款笔数</th>-->
<!--              <th>退款金额</th>-->
<!--              <th>餐盒费</th>-->
<!--              <th>配送费</th>-->
<!--              <th>商家承担配送费</th>-->
<!--              <th>用户承担配送费</th>-->
<!--              <th>其他金额</th>-->
<!--              <th>结算金额(交易金额-其他金额)</th>-->
<!--            </tr>-->
<!--            <tr v-for="(item, index) in [item]" :key="index">-->
<!--              <td>{{ item.id }}</td>-->
<!--              <td>{{ item.shop_name }}</td>-->
<!--              <td>{{ item.pay_count }}</td>-->
<!--              <td>{{ item.pay_money }}</td>-->
<!--&lt;!&ndash;              <td>{{ item.send_count }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;              <td>{{ item.extra_money }}</td>&ndash;&gt;-->
<!--              <td>{{ item.refund_count }}</td>-->
<!--              <td>{{ item.refund_money }}</td>-->
<!--              <td>{{ item.pack_price }}</td>-->
<!--              <td>{{ item.send_price }}</td>-->
<!--              <td>{{ item.shop_bear_send_fee }}</td>-->
<!--              <td>{{ item.user_bear_send_fee }}</td>-->
<!--              <td>{{ item.ledger_money }}</td>-->
<!--              <td>{{ item.settle_money }}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--          <div class="report-foot">-->
<!--            <div class="foot-item">-->
<!--              <div class="foot-label">使用单位: {{username}}</div>-->
<!--              <div class="foot-value">打印时间: {{report.print_date}}</div>-->
<!--            </div>-->
<!--            <div class="foot-item">-->
<!--              <div class="foot-label">操作人: {{report.operator_name}}</div>-->
<!--              <div class="foot-value">出纳: {{report.cashier_name}}</div>-->
<!--            </div>-->
<!--            <div class="foot-item">-->
<!--              <div class="foot-label">负责人: {{report.principal_name}}</div>-->
<!--              <div class="foot-value">会计: {{report.accountant_name}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="index < list.length - 1" class="divide-line"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column v-for="(column, index) in table_columns" :key="index" :prop="column.prop" :label="column.label" min-width="110" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { guid } from "@/utils/index";
import htmlToPdf from "@/utils/htmlToPdf";
import FileSaver from "file-saver";
import XLSX from "xlsx";
var moment = require("moment");
import downloadExcel from "vue-json-excel";

export default {
  components: {
    downloadExcel
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      list: null,
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        shop_ids: [],
        date_type: 1,
        report_type: 1,
        report_order: 1,
        time_type: 0,
        start_date: "",
        end_date: "",
        shop_type: 1,
        show_columns: [],
      },
      btnLoading: false,
      timeArr: null,
      schoolWithShops: [],
      treeIds: [],
      report: {
        report_date: "",
        print_date: moment().format("YYYY-MM-DD"),
        report_type: 1,
        operator_name: '',
        cashier_name: '',
        principal_name: '',
        accountant_name: '',
      },
      all_columns: [],
      table_columns: [],
      json_fields: {},
      exportLoading: false,
    };
  },
  created() {
    this.getSchoolWithShop();
  },
  computed: {
    ...mapGetters(["schools", "school_id", "username"]),
    school_name() {
      return this.schools.filter(element => {
        if (this.school_id == element.id) {
          return true
        }
      })[0].school_name
    },
    selectedColumns () {
      return show_columns => {
        let columns = []
        for(let i=0;i<show_columns.length;i++){
          if (this.all_columns.find((item) => item.prop === show_columns[i])) {
            columns.push(show_columns[i]);
          }
        }
        return columns;
      }
    },
  },
  watch: {
    school_id(newVal, oldVal) {
      this.getSchoolWithShop();
    }
  },
  methods: {
    getReport() {
      if (!this.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      }
      let shop_ids = [];
      // 区分外卖与商城
      if (this.listQuery.shop_type === 1) {
        this.schoolWithShops.forEach(element => {
          element.children.forEach(element2 => {
            element2.children.forEach(element3 => {
              if (this.treeIds.indexOf(element3.treeId) > -1) {
                shop_ids.push(element3.id);
              }
            });
          });
        });
      } else if(this.listQuery.shop_type === 2) {
        this.schoolWithShops.forEach(element => {
          element.children.forEach(element2 => {
            if (this.treeIds.indexOf(element2.treeId) > -1) {
              shop_ids.push(element2.id);
            }
          });
        });
      }

      this.listQuery.shop_ids = shop_ids;
      if (this.listQuery.shop_ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择店铺"
        });
        return;
      }
      if (!this.listQuery.start_date) {
        this.$message({
          type: "warning",
          message: "请选择时间"
        });
        return;
      }
      if (this.listQuery.date_type == 4 && !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间"
        });
        return;
      }
      this.btnLoading = true;
      this.listLoading = true;
      request({
        url: "/api/backend/finance/report",
        method: "post",
        data: this.listQuery,
        timeout: 6000000,
      }).then(response => {
        this.btnLoading = false;
        this.listLoading = false;
        // this.report.report_type = this.listQuery.report_type;
        // let list = response.data.data;
        // let total = {id:'合计', shop_name:'合计', pay_count:0, pay_money:0, send_count:0, extra_money:0, refund_count:0, refund_money:0, pack_price:0, send_price:0, shop_bear_send_fee:0, user_bear_send_fee:0, ledger_money:0, settle_money:0};
        // list.forEach(element => {
        //   element.settle_money = (Math.round((element.pay_money - element.ledger_money) * 100) / 100).toFixed(2)
        //   total.pay_count += element.pay_count
        //   total.pay_money += element.pay_money-0
        //   total.send_count += element.send_count
        //   total.extra_money += element.extra_money-0
        //   total.refund_count += element.refund_count
        //   total.refund_money += element.refund_money-0
        //   total.pack_price += element.pack_price-0
        //   total.send_price += element.send_price-0
        //   total.shop_bear_send_fee += element.shop_bear_send_fee-0
        //   total.user_bear_send_fee += element.user_bear_send_fee-0
        //   total.ledger_money += element.ledger_money-0
        //   total.settle_money += element.settle_money-0
        // });
        // total.pay_money = (Math.round(total.pay_money * 100) / 100).toFixed(2)
        // total.extra_money = (Math.round(total.extra_money * 100) / 100).toFixed(2)
        // total.refund_money = (Math.round(total.refund_money * 100) / 100).toFixed(2)
        // total.ledger_money = (Math.round(total.ledger_money * 100) / 100).toFixed(2)
        // total.pack_price = (Math.round(total.pack_price * 100) / 100).toFixed(2)
        // total.send_price = (Math.round(total.send_price * 100) / 100).toFixed(2)
        // total.shop_bear_send_fee = (Math.round(total.shop_bear_send_fee * 100) / 100).toFixed(2)
        // total.user_bear_send_fee = (Math.round(total.user_bear_send_fee * 100) / 100).toFixed(2)
        // total.settle_money = (Math.round(total.settle_money * 100) / 100).toFixed(2)
        // this.list = list
        // this.list.push(total);
        this.list = response.data.data;
        this.all_columns = response.data.all_columns;
        this.table_columns = response.data.table_columns;
        this.listQuery.show_columns = this.selectedColumns(this.listQuery.show_columns);
        // if (this.listQuery.date_type == 1) {
        //   this.report.report_date = this.listQuery.start_date;
        // } else if (this.listQuery.date_type == 2) {
        //   this.report.report_date = moment(this.listQuery.start_date).format(
        //     "YYYY-M"
        //   );
        // } else if (this.listQuery.date_type == 3) {
        //   this.report.report_date = moment(this.listQuery.start_date).format(
        //     "YYYY"
        //   );
        // } else if (this.listQuery.date_type == 4) {
        //   this.report.report_date =
        //     this.listQuery.start_date + " 至 " + this.listQuery.end_date;
        // }
      });
    },
    getSchoolWithShop() {
      if (!this.school_id) {
        return;
      }
      request({
        url: "/api/backend/school/schoolWithShop",
        method: "get",
        params: {
          school_id: this.school_id,
          type: this.listQuery.shop_type
        }
      }).then(response => {
        let data = response.data;
        let schoolWithShops = [];

        // 区分外卖与商城
        if (this.listQuery.shop_type === 1) {
          data.forEach(element => {
            let obj = {
              treeId: guid(),
              id: element.id,
              label: element.school_name,
              children: []
            };
            element.canteens.forEach(element2 => {
              let obj2 = {
                treeId: guid(),
                id: element2.id,
                label: element2.canteen_name,
                children: []
              };
              element2.shops.forEach(element3 => {
                let obj3 = {
                  treeId: guid(),
                  id: element3.id,
                  label: element3.shop_name,
                  children: []
                };
                obj2.children.push(obj3);
              });
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        } else if(this.listQuery.shop_type === 2) {
          data.forEach(element => {
            let obj = {
              treeId: guid(),
              id: element.id,
              label: element.school_name,
              children: []
            };
            element.shop_list.forEach(element2 => {
              let obj2 = {
                treeId: guid(),
                id: element2.id,
                label: element2.shop_name,
                children: []
              };
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        }

        this.schoolWithShops = schoolWithShops;
      });
    },
    handleFilter() {
      this.getReport();
    },
    treeChange(target, currentCheck, childCheck) {
      let index = this.treeIds.indexOf(target.treeId);
      if (currentCheck) {
        if (index < 0) {
          this.treeIds.push(target.treeId);
        }
      } else {
        if (index > -1) {
          this.treeIds.splice(index, 1);
        }
      }
    },
    printReport() {
      htmlToPdf("report", "营业结算报表");
    },
    handleExportExcel(){
      this.$nextTick(function() {
        let wb = XLSX.utils.table_to_book(document.querySelector("#report"));
        /* get binary string as output */
        let wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "营业结算报表.xlsx");
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
      });
    },
    handleExport() {
      if (!this.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      }
      let shop_ids = [];
      // 区分外卖与商城
      if (this.listQuery.shop_type === 1) {
        this.schoolWithShops.forEach(element => {
          element.children.forEach(element2 => {
            element2.children.forEach(element3 => {
              if (this.treeIds.indexOf(element3.treeId) > -1) {
                shop_ids.push(element3.id);
              }
            });
          });
        });
      } else if(this.listQuery.shop_type === 2) {
        this.schoolWithShops.forEach(element => {
          element.children.forEach(element2 => {
            if (this.treeIds.indexOf(element2.treeId) > -1) {
              shop_ids.push(element2.id);
            }
          });
        });
      }

      this.listQuery.shop_ids = shop_ids;
      if (this.listQuery.shop_ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择店铺"
        });
        return;
      }
      if (!this.listQuery.start_date) {
        this.$message({
          type: "warning",
          message: "请选择时间"
        });
        return;
      }
      if (this.listQuery.date_type == 4 && !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      // listQuery.page = 1;
      // listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/finance/report",
        method: "post",
        data: listQuery,
        timeout: 6000000,
      }).then(response => {
        let json_fields = {};
        response.data.table_columns.forEach(column => {
          this.$set(json_fields, column.label, column.prop);
        });
        this.json_fields = json_fields;

        if (response.data.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.search-container {
  display: flex;
  min-height: 380px;
  padding-bottom: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  border-bottom: 1px solid #f0f0f0;
  .search-tree {
    flex: 0 0 auto;
    width: 300px;
    margin-left: 40px;
    margin-right: 20px;
    .search-label {
      font-size: 14px;
      color: #606266;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .search-value {
      height: 410px;
      overflow: auto;
    }
    ::-webkit-scrollbar{
      width: 5px;
      height: 5px;
    }
    //定义滑块 内阴影+圆角
    ::-webkit-scrollbar-thumb{
      border-radius: 1em;
      background-color: rgba(50,50,50,.3);
    }
    //定义滚动条轨道 内阴影+圆角
    ::-webkit-scrollbar-track{
      border-radius: 1em;
      background-color: rgba(50,50,50,.1);
    }
  }

  .search-options {
    width: 300px;
    flex: 1 0 auto;
    font-size: 14px;
    font-weight: bold;
    color: #606266;
    margin-left: 10px;
    .search-item {
      margin-bottom: 22px;
      .search-label {
        margin-bottom: 8px;
      }
    }
  }
}

.report-container {
  width: 1000px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 20px 0;
  color: #414141;
  position: relative;
  .options {
    display: flex;
    justify-content: flex-end;
    .btn-print {
      margin-right: 8px;
    }
  }

  .report-content {
    padding: 25px 25px 0 25px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .water-mark {
      width: 160%;
      position: absolute;
      top: 0;
      left: -30%;
      z-index: 100;
      color: #67aef7;
      font-size: 16px;
      line-height: 240px;
      transform: rotate(-45deg) translateY(-100px);
      .mark-name {
        display: inline-block;
        margin-left: 50px;
      }
    }
    .report-header {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #414141;
      margin-bottom: 20px;
      .report-header-date {
        margin-top: 6px;
      }
    }
    .report-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      color: #414141;
      .report-label-right {
        min-width: 160px;
      }
    }
    .report-foot {
      margin-top: 10px;
      .foot-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
        .foot-label,
        .foot-value {
          min-width: 160px;
          font-size: 14px;
          color: #414141;
        }
      }
    }
  }
  .divide-line {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #414141 0%, #414141 50%, transparent 50%);
    background-size: 8px 1px;
    background-repeat: repeat-x;
    margin-top: 30px;
  }
}

#report * {
  font-size: 18px !important;
}

.report-table {
  background: #fff;
  border-collapse: collapse;
}
.report-table td, .report-table th {
  border: 1px solid #d2d2d2;
}
.report-table th {
  font-weight: normal;
}
.report-table th, .report-table td {
  width: 8.34%;
  text-align: center;
  padding: 8px;
}

</style>
